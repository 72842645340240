<template lang="pug">
.paginator(:class="{visible: size}")
  span.paginator__text {{ currentPage }}
  span.paginator__text of
  span.paginator__text {{ totalPages }}
  span.d-flex
    btn-icon(@click="$emit('click:prev', page - 1)" :disabled="disabledPrev").mr-1
      ico-arrow-left
    btn-icon(@click="$emit('click:next', page + 1)" :disabled="disabledNext")
      ico-arrow-right
</template>

<script>
import btnIcon from '@/components/global/BtnIcon.vue'

export default {
  props: {
    page: Number,
    size: Number,
    count: Number,
    isZero: Boolean
  },

  computed: {
    totalPages() {
      return Math.ceil(this.count / this.size)
    },

    disabledPrev() {
      return this.page === 1;
    },

    disabledNext() {
      return this.page >= this.totalPages;
    },

    currentPage() {
      return this.isZero ? 0 : this.page
    }
  },

  components: {
    btnIcon,
    icoArrowLeft: () => import('@/assets/img/ui/crm/IcoArrowLeft.vue'),
    icoArrowRight: () => import('@/assets/img/ui/crm/IcoArrowRight.vue')
  }
}
</script>

<style lang="scss">
.paginator {
  display: flex;
  align-items: center;
  padding-right: 15px;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
    transition: 0.5s ease-in-out;
  }

  &__text {
    font-family: $font;
    font-size: 12px;
    font-weight: bold;
    color: $placeholder-color;
    padding-right: 4px;
  }

  &__buttons {
    display: inline-block;
  }
}
</style>
